import { Component, OnInit } from '@angular/core';
import { DataBaseService } from '../provider.service';
import { AppComponent } from '../app.component';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-financeiro',
  templateUrl: './financeiro.component.html',
  styleUrls: ['./financeiro.component.scss']
})

export class FinanceiroComponent implements OnInit {
 financeiro:any = [];
 usuario:any = "";

  constructor(public app: AppComponent,public db:DataBaseService){}

   ngOnInit(){
    this.db.getAssociadosById(localStorage.getItem('idUserVM')).subscribe((user:any) => {
      this.usuario = user;
      this.db.getFinanceiroByIdUser(user._id).subscribe((c:any)=>{
        if(c){
          this.financeiro = c.sort((a:any,b:any) => {if(a.vencimento > b.vencimento)return -1});;
        }
      })
    })
  }

  voltarInicio(){
    this.app.router.navigate(['/inicio']);
  }

  downloadBoleto(url){
    if(url){
      let splitUrl = url.split('.');
      let extensao = splitUrl[splitUrl.length - 1];
     FileSaver.saveAs(url, "vivamax."+extensao);
    }else{
      alert("Boleto não disponível, por favor entrar em contato com a Viva Max")
    }
 }

}