<div class="main">
    <div id="escolheEmpresa" class="modalChoice">
        <div class="innerModalChoice">
            <img style="width:260px;margin:0 auto" src="../../assets/img/logo.png">
            <br>
            <p class="descricao">Localizamos mais de um CPF registrado em nosso sistema, por favor, selecione para qual produto deseja acesso:</p><br>
            <div class="empresaChoice" *ngFor="let e of empresasCPF" (click)="selectEmpresaCPF(e)">
                <img style="width:30px;margin:10px" [src]="e.empresa == 'CONECTA' ? '../assets/img/vBlue.png' : '../assets/img/logo-icon.png'">{{e.empresa}}
            </div>
            <br><br>
            <div class="row" style="text-align: center;">
                <div class="col">
                    <button class="btn btn-dark" (click)="closeModalEscolheEmpresa()">Voltar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="left" id="leftCol">
        <div class="logoLogin" id="logoLogin">
            <img src="../../assets/img/logo.png">           
        </div>
        <div class="logoLoginMob" *ngIf="!app.isDesktop">
            <img src="../../assets/img/logo.png">           
        </div>
        <div class="containerLogin" id="containerLog">
            <div class="titulo">
                Área do cliente
            </div>
            <div class="descricao">
                Na área do cliente você pode visualizar nossa rede de crendeciados, editar os seus dados de contato e consultar seu histórico de agendamentos. Acesse abaixo e confira!
            </div>
            <div class="botoes">
                <input type="text" placeholder="CPF / CNPJ" class="form-control" [(ngModel)]="cpf" [ngModelOptions]="{standalone: true}" (focus)="retirarFormatacao();" (blur)="formatarCampo();" maxlength="14" [disabled]="userOkey"/>
                <input type="password" class="form-control" placeholder="Senha" [(ngModel)]="senha" [ngModelOptions]="{standalone: true}" *ngIf="userOkey">
                <button class="btn btn-primary" [disabled]="cpf.length < 11" (click)="verificarUsuario()" *ngIf="!userOkey">Avançar</button>
                <div class="row" style="margin-right: -10px;">
                    <div class="col col-12 col-md-6">
                        <button class="btn btn-dark" (click)="resetSenha()" *ngIf="userOkey">Trocar</button>
                    </div>
                    <div class="col col-12 col-md-6">
                        <button class="btn btn-primary" (click)="logger()" [disabled]="!cpf || !senha" *ngIf="userOkey">Entrar</button>
                    </div>
                </div>
            </div>
            <button class="ghost-button">
                <div class="esqueceu">
                    <p>Esqueceu sua senha? <span class="link" (click)="openEsqueceuSenha()">Clique aqui.</span></p>
                </div>
            </button>
            <button class="ghost-button">
                <div class="resenha">
                    <p>Ainda não tem um plano? <span class="link" (click)="openCadastroFuncionario()">Clique aqui</span> e cadastra-se de forma fácil e sem burocracia.</p>
                </div>
            </button>
            <div style="font-size:0.7em;color:#3b3b3b;width: 100%;text-align: center;">
                V.1.2
            </div>
        </div>
    </div>
    <div class="right" id="rightCol">
        <div id="logo" class="logoCenter">
            <img src="../../assets/img/logo.png">
        </div>
        <div class="modalCadastroSenha" id="cadastroSenha">
            <div class="row descricao boxed">
                <div class="col">
                    Cadastre abaixo a sua senha para a área do cliente.
                </div>
            </div>
            <div class="botoes">
                <input type="number" placeholder="Matricula" class="form-control" [(ngModel)]="confirmarMatricula" [ngModelOptions]="{standalone: true}" [hidden]="codigo">
                <input type="password" placeholder="Nova Senha" class="form-control" [(ngModel)]="novaSenha" [ngModelOptions]="{standalone: true}">
                <input type="password" placeholder="Repita sua senha" class="form-control" [(ngModel)]="confirmaNovaSenha" [ngModelOptions]="{standalone: true}">
                <small style="color:#c7444a" *ngIf="(novaSenha && confirmaNovaSenha) && (novaSenha != confirmaNovaSenha)">As senhas devem ser iguais</small>
                <div class="row" style="margin:0 auto">
                    <div  class="col col-12 col-md-6">
                        <button class="btn btn-dark" (click)="closeCadastrarSenha()">CANCELAR</button>                
                    </div>
                    <div  class="col col-12 col-md-6">
                        <button class="btn btn-primary" (click)="cadastrarNovaSenha()" [disabled]="!confirmarMatricula || !novaSenha || !confirmaNovaSenha || (novaSenha != confirmaNovaSenha)" [hidden]="codigo">SALVAR</button>
                        <button class="btn btn-primary" (click)="cadastrarNovaSenha()" [disabled]="!novaSenha || !confirmaNovaSenha || (novaSenha != confirmaNovaSenha)" [hidden]="!codigo">SALVAR</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modalNovaSenha" id="cadastroSenhaCnpj">
            <div class="row descricao boxed">
                <div class="col">
                    Insira abaixo uma nova senha para sua área da Empresa.
                </div>
            </div>
            <div class="botoes">
                <input type="text" placeholder="Código da Empresa" class="form-control" [(ngModel)]="codigoEmpresaSenha" [ngModelOptions]="{standalone: true}">
                <input type="password" placeholder="Nova Senha" class="form-control" [(ngModel)]="novaSenha" [ngModelOptions]="{standalone: true}">
                <input type="password" placeholder="Repita sua senha" class="form-control" [(ngModel)]="confirmaNovaSenha" [ngModelOptions]="{standalone: true}">
                <small style="color:#c7444a" *ngIf="(novaSenha && confirmaNovaSenha) && (novaSenha != confirmaNovaSenha)">As senhas devem ser iguais</small>
                <button class="btn btn-primary" (click)="cadastrarNovaSenhaCnpj()" [disabled]="!novaSenha || !confirmaNovaSenha || (novaSenha != confirmaNovaSenha)">SALVAR</button>
            </div>
        </div>
        <div class="modalEsqueceuSenha" id="esqueceuSenhaDados">
            <div class="logoLoginMob" *ngIf="!app.isDesktop">
                <img src="../../assets/img/logo.png">           
            </div>
            <div class="row descricao boxed">
                <div class="col" [hidden]="codigo">
                    Preencha as informações abaixo para receber um código de validação em seu celular.
                </div>
                <div class="col" [hidden]="!codigo">
                    Aguarde um instante, você irá receber o código no seu celular.
                </div>
            </div>
            <div style="height: 20px;"></div>
            <div class="botoes" style="padding: 15px;">
                <input type="text" placeholder="CPF" [textMask]="{mask: MASKS.cpf.textMask}" class="form-control" [(ngModel)]="cpfEsqueceuSenha" [ngModelOptions]="{standalone: true}">
                <input type="number" placeholder="Matricula" class="form-control" [(ngModel)]="matriculaRecuperacao" [ngModelOptions]="{standalone: true}">
                <input type="tel" placeholder="Celular" [textMask]="{mask: MASKS.telefone.textMaskFunction}" class="form-control" [(ngModel)]="celularEsqueceuSenha" [ngModelOptions]="{standalone: true}">
                <div class="row" style="margin-right:-10px">
                    <div class="col col-12 col-md-6">
                        <input type="number" placeholder="Código SMS" class="form-control" style="font-weight: 800;" [hidden]="!codigo" [(ngModel)]="codigoEsqueceuSenha" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col col-12 col-md-6">
                        <button class="btn btn-warning" (click)="solicitarCodigo()" [hidden]="!codigo" [disabled]="countdown != 59 || !matriculaRecuperacao || (!cpfEsqueceuSenha || !celularEsqueceuSenha)">
                            <span *ngIf="countdown != 59"><strong >REENVIAR SMS (00:{{countdown}})</strong></span>
                            <span *ngIf="countdown == 59"><strong >REENVIAR SMS</strong></span>
                        </button>
                    </div>
                </div>                    
                <div class="row" style="margin-right:-10px">
                    <div class="col col-12 col-md-6">
                        <button class="btn btn-dark" (click)="closeEsqueceuSenha()">CANCELAR</button>
                    </div>
                    <div class="col col-12 col-md-6">
                        <button class="btn btn-primary" (click)="solicitarCodigo()" [hidden]="codigo" [disabled]="!cpfEsqueceuSenha || !celularEsqueceuSenha || counterCheck">RECEBER CÓDIGO</button>
                        <button class="btn btn-primary" (click)="esqueceuSenha()" [hidden]="!codigo" [disabled]="!cpfEsqueceuSenha || !celularEsqueceuSenha|| !codigoEsqueceuSenha">PROSSEGUIR</button>
                    </div>
                </div>
            </div>
        </div>
            <div class="modalFirst" id="firstAccess">
                <div class="select" *ngIf="!novoPlanoEmpresa">
                    <div class="selectInside" (click)="selectPlanoEmpresa()">
                        <fa-icon [icon]="['fas', 'user']"></fa-icon>
                        <label>PLANO EMPRESARIAL</label>
                        <p>Minha empresa possui convênio e vim me cadastrar</p>
                    </div>
                    <div class="selectInside" (click)="goToSite()">
                        <fa-icon [icon]="['fas', 'users']"></fa-icon>
                        <label>PLANO FAMILIAR</label>
                        <p>Eu quero contratar um plano para mim e/ou minha familia</p>
                    </div>
                </div>
                <div class="row align-items-center" style="width: 100%;text-align: center;padding-top: 5em;" *ngIf="!novoPlanoEmpresa">
                    <div class="col">
                        <button class="btn btn-dark" (click)="closeCadastroFuncionario()">VOLTAR</button>
                    </div>
                </div>
                <mat-horizontal-stepper linear #stepper class="full-width" *ngIf="novoPlanoEmpresa"> 
                    <mat-step [stepControl]="1" [editable]="false" #stepper>
                        <div class="row" style="text-align: center;">
                            <div class="col">
                                <label style="font-size: 1.4em;margin-bottom: 2em;">
                                    Seja bem vindo à<br>
                                    Viva Max - Plano Familiar<br>
                                </label>
                                <br><br>
                                <input type="text" class="form-control" [(ngModel)]="codEmpresa" placeholder="Digite o Código da Empresa" style="text-align:center;font-weight:bold;">
                            </div>
                        </div>
                        <br><br>
                        <div class="row" style="text-align: center;">
                            <div class="col">
                                <button class="btn btn-dark" (click)="closeCadastroFuncionario()">CANCELAR</button>
                            </div>
                            <div class="col">
                                <button class="btn btn-primary" [disabled]="!codEmpresa" (click)="buscarEmpresa(codEmpresa)">AVANÇAR</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [stepControl]="2" [editable]="false">
                        <div class="row" style="text-align: center;font-weight: bold;">
                            <div class="col">
                                <label>{{empresaSelecionada.fantasiaEmpresa}}</label>
                            </div>
                        </div>
                        <br>
                        <div class="row" style="text-align: center;">
                            <div class="col">
                                <label style="font-size: 1.4em;">Dados Pessoais</label>
                            </div>
                        </div>
                        <br><br>
                        <div class="row">
                            <div class="col col-12 col-md-8">
                                <label>Nome Completo:</label>
                                <input type="text" class="form-control" [(ngModel)]="nomeCompleto" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nomeCompleto ? '#dd443e' : '#3cb42c'}">
                            </div>
                            <div class="col col-12 col-md-4">
                                <label>Estado Civil:</label>
                                <select class="form-control" [(ngModel)]="estadoCivil"  [ngStyle]="{'border-color': !estadoCivil ? '#dd443e' : '#3cb42c'}">
                                    <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                                </select>
                            </div>
                            <div class="col col-12 col-md-4">
                                <label>Sexo:</label>
                                <select class="form-control" [(ngModel)]="sexoCadastro" [ngStyle]="{'border-color': !sexoCadastro ? '#dd443e' : '#3cb42c'}">
                                    <option value="MASCULINO">MASCULINO</option>
                                    <option value="FEMININO">FEMININO</option>
                                </select>
                            </div>
                            <div class="col col-12 col-md-4">
                                <label>CPF:</label>
                                <input type="text" class="form-control" (keyup)="app.checkCPF(cpfCadastro)" [(ngModel)]="cpfCadastro" cpf [textMask]="{mask: MASKS.cpf.textMask}" (change)="cpfUnico(cpfCadastro)" [ngStyle]="{'border-color': !cpfCadastro ? '#dd443e' : '#3cb42c'}">
                                <div class="errorMsg" *ngIf="cpfCadastro && !app.checkCPF(cpfCadastro)">CPF incorreto!</div>
                            </div>
                            <div class="col col-6 col-md-4">
                                <label>Nascimento:</label>
                                <input type="date" class="form-control" [(ngModel)]="nascimento" [ngStyle]="{'border-color': !nascimento? '#dd443e' : '#3cb42c'}">
                            </div>
                            <div class="col col-6 col-md-4">
                                <label>Telefone:</label>
                                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone">
                            </div>
                            <div class="col col-6 col-md-4">
                                <label>Celular:</label>
                                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="celular"  [ngStyle]="{'border-color': !celular ? '#dd443e' : '#3cb42c'}">
                            </div>
                            <div class="col col-6 col-md-4">
                                <br>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="whatsapp" id="whatsCadastro">
                                    <label class="form-check-label" for="whatsCadastro">&nbsp;&nbsp;Whatsapp</label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row align-items-center">
                            <div class="col col-12 col-md-8">
                                <label>Função na Empresa:</label>
                                <input type="text" class="form-control" [(ngModel)]="funcao" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="col col-12 col-md-4">
                                <label>E-mail:</label>
                                <input type="text" class="form-control" [(ngModel)]="emailCadastro" autocomplete="off"
                                [ngStyle]="{'border-color': !emailCadastro ? '#dd443e' : '#3cb42c'}"
                                (keyup)="app.checkEmail(emailCadastro)" oninput="this.value = this.value.toUpperCase()">
                                <div class="errorMsg" *ngIf="emailCadastro && !app.checkEmail(emailCadastro)">E-mail inválido!</div>
                            </div>
                        </div>
                        <br>
                        <div class="row align-items-center">
                            <div class="col col-12 col-md-6">
                                <label>Senha:</label>
                                <input type="password" class="form-control" [(ngModel)]="senhaCadastro"  [ngStyle]="{'border-color': !senhaCadastro ? '#dd443e' : '#3cb42c'}">
                            </div>
                            <div class="col col-12 col-md-6">
                                <label>Confirmar Senha:</label>
                                <input type="password" class="form-control" [(ngModel)]="confirmarSenhaCadastro"  [ngStyle]="{'border-color': (!confirmarSenhaCadastro || senhaCadastro != confirmarSenhaCadastro) ? '#dd443e' : '#3cb42c'}">
                            </div>
                        </div>
                        <br><br>
                        <div class="row" style="text-align: center;">
                            <div class="col">
                                <button class="btn btn-dark" (click)="closeCadastroFuncionario()">CANCELAR</button>
                            </div>
                            <div class="col">
                                <button class="btn btn-primary" [disabled]="!cpfCheck || !sexoCadastro || !nomeCompleto || !cpfCadastro || !celular || !nascimento || !emailCadastro || (!confirmarSenhaCadastro || senhaCadastro != confirmarSenhaCadastro) || !senhaCadastro" (click)="avancaStep()">AVANÇAR</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [stepControl]="3" [editable]="true">
                        <div class="row" style="text-align: center;">
                            <div class="col">
                                <label style="font-size: 1.4em;">Endereço</label>
                            </div>
                        </div>
                        <br><br>
                        <div class="row">
                            <div class="col col col-12 col-md-3">
                                <label>CEP:</label>
                                <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cep" (keyup)="buscarCep(cep)">
                            </div>
                            <div class="col col col-12 col-md-6">
                                <label>Rua:</label>
                                <input type="text" class="form-control" [(ngModel)]="rua" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !rua ? '#dd443e' : '#3cb42c'}">
                            </div>
                            <div class="col col col-12 col-md-3">
                                <label>Número:</label>
                                <input type="text" class="form-control" [(ngModel)]="numero" [ngStyle]="{'border-color': !numero ? '#dd443e' : '#3cb42c'}">
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col col col-12 col-md-5">
                                <label>Bairro:</label>
                                <input type="text" class="form-control" [(ngModel)]="bairro" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairro ? '#dd443e' : '#3cb42c'}">
                            </div>
                            <div class="col col col-12 col-md-3">
                                <label>Complemento:</label>
                                <input type="text" class="form-control" [(ngModel)]="complemento" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="col col col-10 col-md-3">
                                <label>Município:</label>
                                <input type="text" class="form-control" [(ngModel)]="municipio" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !municipio ? '#dd443e' : '#3cb42c'}">
                            </div>
                            <div class="col col col-2 col-md-1">
                                <label>UF:</label>
                                <select class="form-control" [(ngModel)]="estado" [ngStyle]="{'border-color': !estado ? '#dd443e' : '#3cb42c'}">
                                    <option value="AC">AC</option>
                                    <option value="AL">AL</option>
                                    <option value="AM">AM</option>
                                    <option value="AP">AP</option>
                                    <option value="BA">BA</option>
                                    <option value="CE">CE</option>
                                    <option value="DF">DF</option>
                                    <option value="ES">ES</option>
                                    <option value="GO">GO</option>
                                    <option value="MA">MA</option>
                                    <option value="MG">MG</option>
                                    <option value="MS">MS</option>
                                    <option value="MT">MT</option>
                                    <option value="PA">PA</option>
                                    <option value="PB">PB</option>
                                    <option value="PE">PE</option>
                                    <option value="PI">PI</option>
                                    <option value="PR">PR</option>
                                    <option value="RJ">RJ</option>
                                    <option value="RN">RN</option>
                                    <option value="RS">RS</option>
                                    <option value="RO">RO</option>
                                    <option value="RR">RR</option>
                                    <option value="SC">SC</option>
                                    <option value="SE">SE</option>
                                    <option value="SP">SP</option>
                                    <option value="TO">TO</option>
                                </select>
                            </div>
                        </div>
                        <br><br>
                        <div class="row" style="text-align: center;">
                            <div class="col">
                                <button class="btn btn-dark" (click)="closeCadastroFuncionario()">CANCELAR</button>
                            </div>
                            <div class="col">
                                <button class="btn btn-primary" [disabled]="!rua || !numero || !bairro || !municipio || !estado" (click)="cadastrarAssociadoAuto()">FINALIZAR</button>
                            </div>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
</div>