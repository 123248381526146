import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { AuthService } from '../auth.service';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})

export class InicioComponent implements OnInit {

  constructor(public app: AppComponent, public auth:AuthService, public db:DataBaseService) { }

  ngOnInit():void{
    if(localStorage.getItem('idUserVM')){
      if(localStorage.getItem('hibridoVM') == 'true'){
        this.app.hibrido = true;
      }else{
        this.app.hibrido = false;
      }
      this.db.getAssociadosById(localStorage.getItem('idUserVM')).subscribe((data:any) =>{
        let tipo = localStorage.getItem('tipoUsuarioVM');
        switch (tipo){
          case "Titular":
            if(!data.agenda){
              this.db.patchAssociado(localStorage.getItem('idUserVM'),{agenda:[]}).subscribe(res => console.log(res), err => console.log(err));
            }
            break;
          case "Dependente":
            let indexDep = data.dependentes.map((itemDep:any) => {
              return itemDep.cpf;
            }).indexOf(localStorage.getItem('cpfVM'))
            if(indexDep >= 0){
              if(!data.dependentes[indexDep].agenda){
                this.db.createAgendaDependente(localStorage.getItem('idUserVM'),localStorage.getItem('cpfVM')).subscribe(res => console.log(res), err => console.log(err));
              }
            }
            break;
          case "Dependente Extra":
            let indexDepExtra = data.dependentesExtra.map((itemDepExtra:any) => {
              return itemDepExtra.cpf;
            }).indexOf(localStorage.getItem('cpfVM'))
            if(indexDepExtra >= 0){
              if(!data.dependentesExtra[indexDepExtra].agenda){
                this.db.createAgendaDependenteExtra(localStorage.getItem('idUserVM'),localStorage.getItem('cpfVM')).subscribe(res => console.log(res), err => console.log(err));
              }
            }
            break;
          default: break;
        }
      })
    }else{
      this.auth.logOut();
    }
    
  }

}
