import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';
import { MASKS } from 'ng-brazil';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-contrato',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.scss']
})

export class ContratoComponent implements OnInit {
  public MASKS = MASKS;
  checkCienteAdesao:boolean = false;
  alterando:boolean = false;
  dependenteAlteracao:any = [];
  dependenteAlterado:any = [];
  arrayEmails:any = [];
  tipoDependente:any = "";
  nomeDependente:any = "";
  nascimentoDependente:any = "";
  cpfDependente:any = "";
  asfDependente:any = "";
  asfValorDependente:any = "";
  nomeUsuario:any = "";
  idUser:any = "";
  usuario:any = [];
  contrato:any = "";
  cidades:any = [];
  estado:any = "";
  municipio:any = "";
  tipoAlteracaoDependente:any = "";
  dependentesExcluir:any = [];
  plano:any = [];
  dataHoje:any = "";
  maisSessentaNove:boolean = false;
  maisSessentaNoveEditar:boolean = false;
  conectaInclusos:number = 0;
  valorConectaExtra:number = 0;
  maxDependentesConecta:number = 0;
  dependentes:FormGroup;
  dependentesExtra:FormGroup;
  dependentesEditar:FormGroup;
  dependentesExtraEditar:FormGroup;
  public listaDeDependentes:FormArray;
  public listaDeDependentesExtra:FormArray;
  public listaDeDependentesEditar:FormArray;
  public listaDeDependentesExtraEditar:FormArray;
  get dependentesFormGroup(){return this.dependentes.get('dependente') as FormArray;};
  get dependentesExtraFormGroup(){return this.dependentesExtra.get('dependenteExtra') as FormArray;};
  get dependentesEditarFormGroup(){return this.dependentesEditar.get('dependenteEditar') as FormArray;};
  get dependentesExtraEditarFormGroup(){return this.dependentesExtraEditar.get('dependenteExtraEditar') as FormArray;};
  emailDependente:any = "";
  sexoDependente:any = "";
  conectaDependente:any = "";
  constructor(public app:AppComponent,public db:DataBaseService,private formBuilder: FormBuilder) { }

  ngOnInit(){
    this.idUser =  localStorage.getItem('idUserVM');
    this.nomeUsuario = localStorage.getItem('nomeUsuarioVM');
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.db.getAssociadosById(this.idUser).subscribe((data:any) => {
      this.usuario = data;
      this.estado = data.estado;
      this.municipio = data.municipio;
      this.app.hibrido = data.hibrido;
      let tipo:any = "";
      if(this.usuario.planoFisicoCheck == true){
        tipo = "PLANO FISICO"
      }
      if(this.usuario.planoFisicoCheck == false && (this.usuario.planoEmpresarialCheck == false || this.usuario.planoEmpresarialCheck == null)){
        tipo = "PLANO SERVIDOR"
      }
      if(this.usuario.planoEmpresarialCheck == true){
        tipo = "PLANO EMPRESARIAL"
      }
      this.db.getPlanosByTipoByNome(tipo,this.usuario.plano).subscribe((dtPlano:any) => {
        if(dtPlano && dtPlano.length > 0){
          this.plano = dtPlano[0];
          this.contrato = dtPlano[0].contrato;
          this.conectaInclusos = this.plano.conectaInclusos;
          this.valorConectaExtra = this.plano.valorConectaExtra;
          this.maxDependentesConecta = this.plano.maxDependentesConecta;
        }
      });
    });
    this.db.getAllUsuarios().subscribe((userData:any) => {
      this.arrayEmails = userData;
      })
    this.db.getCidadesConfig().subscribe((data:any) => this.cidades = data.cidades);
    this.dependentes = this.formBuilder.group({
      dependente: this.formBuilder.array([])
    });
    this.dependentesExtra = this.formBuilder.group({
      dependenteExtra: this.formBuilder.array([])
    });
    this.dependentes = this.formBuilder.group({
      dependente: this.formBuilder.array([])
    });
    this.dependentesExtra = this.formBuilder.group({
      dependenteExtra: this.formBuilder.array([])
    });
    this.dependentesEditar = this.formBuilder.group({
      dependenteEditar: this.formBuilder.array([])
    });
    this.dependentesExtraEditar = this.formBuilder.group({
      dependenteExtraEditar: this.formBuilder.array([])
    });
    this.listaDeDependentes = this.dependentes.get('dependente') as FormArray;
    this.listaDeDependentesExtra = this.dependentesExtra.get('dependenteExtra') as FormArray;
    this.listaDeDependentesEditar = this.dependentesEditar.get('dependenteEditar') as FormArray;
    this.listaDeDependentesExtraEditar = this.dependentesExtraEditar.get('dependenteExtraEditar') as FormArray;
  }

  createDependente():FormGroup{
    return this.formBuilder.group({
      tipo:[null, Validators.compose([Validators.required])],
      nome: [null, Validators.compose([Validators.required])],
      nascimento:[null, Validators.compose([Validators.required, Validators.minLength(10)])],
      cpf: [null, [Validators.minLength(14)]],
      asf: [null],
      conecta: [this.app.tipoEmpresa == 'CONECTA' && !this.app.hibrido ? true : null],
      email: [null],
      sexo: [null],
      asfValor:[null]
    });
  }
  
  addDependente(){
    this.listaDeDependentes.push(this.createDependente());
  }
  
  removeDependente(index){
    this.listaDeDependentes.removeAt(index);
  }
  
  createDependenteExtra():FormGroup{
    return this.formBuilder.group({
      tipo:[null, Validators.compose([Validators.required])],
      nome: [null, Validators.compose([Validators.required])],
      nascimento:[null, Validators.compose([Validators.required])],
      cpf: [null],
      asf:[null],
      conecta: [null],
      email: [null],
      sexo: [null],
      asfValor:[null]
    });
  }
  
  addDependenteExtra(){
    this.listaDeDependentesExtra.push(this.createDependenteExtra());
  }
  
  removeDependenteExtra(index){
    this.listaDeDependentesExtra.removeAt(index);
  }
  
  createDependenteEditar(item):FormGroup{
    return this.formBuilder.group({
      tipo:item.tipo,
      nome: item.nome,
      nascimento:item.nascimento,
      cpf:item.cpf,
      conecta: item.conecta,
      email: item.email,
      sexo: item.sexo,
      asf:item.asf
    });
  }

  addDependenteEditar(item){
    this.listaDeDependentesEditar.push(this.createDependenteEditar(item));
  }

  removeDependenteEditar(index){
    if(window.confirm("Deseja realmente remover esse Dependente?")){
      if(this.listaDeDependentesEditar.value[index].sinistro){
        alert("Dependente Possui Sinistro Registrado, Impossível Remover!")
      }else{
        this.listaDeDependentesEditar.removeAt(index);
      }
    }
  }

  createDependenteExtraEditar(item):FormGroup{
    return this.formBuilder.group({
      tipo:item.tipo,
      nome: item.nome,
      nascimento:item.nascimento,
      cpf:item.cpf,
      conecta: item.conecta,
      email: item.email,
      sexo: item.sexo,
      asf:item.asf
    });
  }

  addDependenteExtraEditar(item){
    this.listaDeDependentesExtraEditar.push(this.createDependenteExtraEditar(item));
  }

  removeDependenteExtraEditar(index){
    if(window.confirm("Deseja realmente remover esse Dependente?")){
      if(this.listaDeDependentesExtraEditar.value[index].sinistro){
        alert("Dependente Possui Sinistro Registrado, Impossível Remover!")
      }else{
        this.listaDeDependentesExtraEditar.removeAt(index);
      }
    }
  }

  gotoTop(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  baixarClausulas(){
    FileSaver.saveAs(this.contrato, "contrato-vivamax");
  }

  openEditar(){
    let mainRight = document.getElementById('mainRight');
    mainRight.style.display = 'none';
    let modalDados = document.getElementById('modalDados');
    modalDados.style.display = 'block';
    this.gotoTop();
  }

  closeEditar(){
    this.alterando = false;
    this.dependenteAlteracao = [];
    this.dependenteAlterado = [];
    this.tipoDependente = "";
    this.nomeDependente = "";
    this.nascimentoDependente = "";
    this.cpfDependente = "";
    this.checkCienteAdesao = false;
    this.emailDependente = "";
    this.sexoDependente = "";
    this.conectaDependente = "";
    let modalDados = document.getElementById('modalDados');
    modalDados.style.display = 'none';
    let mainRight = document.getElementById('mainRight');
    mainRight.style.display = 'block';
    mainRight.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.gotoTop();
  }

  salvar(){
    let obj = {
      telefone: this.usuario.telefone,
      celular: this.usuario.celular,
      email: this.usuario.email,
      rua: this.usuario.rua,
      numero: this.usuario.numero,
      cep: this.usuario.cep,
      bairro: this.usuario.bairro,
      municipio: this.municipio,
      estado: this.estado,
      complemento: this.usuario.complemento
    }
    this.db.patchAssociado(this.idUser,obj).subscribe(res => {
      console.log(res);
      this.usuario.estado = this.estado;
      this.usuario.municipio = this.municipio;
      this.closeEditar();
    }, err => {
      console.log(err);
      alert("Erro ao atualizar cadastro, tente novamente mais tarde!");
    })
  }

  changeMunicipio(e){
    this.estado = e.estado;
    this.municipio = e.cidade;
  }

  openEditarDependentes(){
    let mainRight = document.getElementById('mainRight');
    mainRight.style.display = 'none';
    let modalDep = document.getElementById('modalDependentes');
    modalDep.style.display = 'block';
    this.gotoTop();
  }
  
selectTipoDependente(i){
  let tipoInput = (<HTMLInputElement>document.getElementById('tipo'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asf'+i));
  let valorInput = (<HTMLInputElement>document.getElementById('asfValor'+i));
    if(tipoInput == "CÔNJUGE" && this.plano.funeralConjuge == "AUTOMATICO"){
        asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
        this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO');
        valorInput.value = "0";
        this.dependentes.get('dependente.'+i+'.asfValor').patchValue('0');
    }else{
      asfInput.value = "";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('');
    }
    if((tipoInput == "FILHO" || tipoInput == "FILHA") && this.plano.funeralFilhosMenor == "AUTOMATICO"){
      asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS');
      valorInput.value = "0";
      this.dependentes.get('dependente.'+i+'.asfValor').patchValue(0);
    }else{
      asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO');
      valorInput.value = "0";
      this.dependentes.get('dependente.'+i+'.asfValor').patchValue(0);
    }
  }
  
selectTipoDependenteEditar(){
    this.nascimentoDependente = "";
    if(this.tipoDependente == "CÔNJUGE"){
      if(this.plano.funeralConjuge == "AUTOMATICO"){
              this.asfDependente = "FUNERAL AUTOMÁTICO VITALÍCIO";
              this.asfValorDependente = "0";
          }else{
            this.asfDependente = "NÃO HÁ FUNERAL CONTRATADO";
            this.asfValorDependente = "0";
          }
    }else if(this.tipoDependente == "FILHO" || this.tipoDependente == "FILHA"){
      if(this.plano.funeralFilhosMenor == "AUTOMATICO"){
        this.asfDependente = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
        this.asfValorDependente = "0";
        }else{
          this.asfDependente = "NÃO HÁ FUNERAL CONTRATADO";
          this.asfValorDependente = "0";
        }
    }else{
      this.asfDependente = "NÃO HÁ FUNERAL CONTRATADO";
      this.asfValorDependente = "0";
    }
  }
  
  verificaNascimentoEditar(nascimento){
    if(this.tipoDependente == "FILHO" || this.tipoDependente == 'FILHA'){
      if(!this.verificaIdade(nascimento)){
         if(this.plano.funeralFilhosMenor == "AUTOMATICO"){
           this.asfDependente = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
           this.asfValorDependente = "0";
         }
     }else{
      this.plano.filhoMenorIdadeCheck = false;
      this.asfDependente = "NÃO HÁ FUNERAL CONTRATADO";
      this.asfValorDependente = "0";
     }      
   }else{
    this.verificaIdadeEditar(nascimento);
     return;
   }
}

  verificaNascimento(i){
    let tipoInput = (<HTMLInputElement>document.getElementById('tipo'+i)).value;
    let asfInput = (<HTMLInputElement>document.getElementById('asf'+i));
    let nascimentoInput = (<HTMLInputElement>document.getElementById('nascimento'+i));
    let valorInput = (<HTMLInputElement>document.getElementById('asfValor'+i));
    if(tipoInput == "FILHO" || tipoInput == 'FILHA'){
      if(!this.verificaIdade(nascimentoInput.value)){
         if(this.plano.funeralFilhosMenor == "AUTOMATICO"){
           asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
           asfInput.disabled = true;
           this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS');
           valorInput.value = "0";
           this.dependentes.get('dependente.'+i+'.asfValor').patchValue(0);
         }
     }else{
      this.plano.filhoMenorIdadeCheck = false;
      asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
      asfInput.disabled = false;
      this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO')
      valorInput.value = "0";
      this.dependentes.get('dependente.'+i+'.asfValor').patchValue(0);
     }      
   }else{
    this.verificaIdade(nascimentoInput.value);
     return;
   }
}

verificaIdade(e){
  if(e){
    var d = new Date;
    let dt = e.split('/').reverse().join('-');
    let data = new Date(dt.split('-')[0],dt.split('-')[1]-1,dt.split('-')[2])
    let ano_atual = d.getFullYear();
    let mes_atual = d.getMonth() + 1;
    if(mes_atual == 12){
      mes_atual = 0
    }
    let dia_atual = d.getDate();
    let ano_aniversario = data.getFullYear();;
    let mes_aniversario = data.getMonth() + 1;
    if(mes_aniversario == 12){
      mes_aniversario = 0
    }
    let dia_aniversario = data.getDate();
    let idade = ano_atual - ano_aniversario;
    if(mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario){
        idade--;
    }
    if(idade >= 18 && idade < 70){
      this.maisSessentaNove = false;
      return true;
    }else if(idade >= 18 && idade >= 70){
      this.maisSessentaNove = true;
      return true;
    }else{
      this.maisSessentaNove = false;
      return false;
    }
  }else{
    return false;
  }
}

verificaIdadeEditar(e){
  if(e){
    var d = new Date;
    let dt = e.split('/').reverse().join('-');
    let data = new Date(dt.split('-')[0],dt.split('-')[1]-1,dt.split('-')[2])
    let ano_atual = d.getFullYear();
    let mes_atual = d.getMonth() + 1;
    if(mes_atual == 12){
      mes_atual = 0;
    }
    let dia_atual = d.getDate();
    let ano_aniversario = data.getFullYear();;
    let mes_aniversario = data.getMonth() + 1;
    if(mes_aniversario == 12){
      mes_aniversario = 0;
    }
    let dia_aniversario = data.getDate();
    let idade = ano_atual - ano_aniversario;
    if(mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario){
        idade--;
    }
    if(idade >= 18 && idade < 70){
      this.maisSessentaNoveEditar = false;
      return true;
    }else if(idade >= 18 && idade >= 70){
      this.maisSessentaNoveEditar = true;
      return true;
    }else{
      this.maisSessentaNoveEditar = false;
      return false;
    }
  }else{
    return false;
  }
}

  selectAsf(tipo, asf,i){
    let valorInput = (<HTMLInputElement>document.getElementById('asfValor'+i));
    if(asf == "NÃO HÁ FUNERAL CONTRATADO"){
      valorInput.value = "0";
      this.dependentes.get('dependente.'+i+'.asfValor').patchValue('0');
    }else{
      if(tipo == "FILHO" || tipo == "FILHA"){
        if(this.plano.funeralFilhosMaior == "SIM"){
          valorInput.value = this.plano.valorFuneralFilhosMaior;
          this.dependentes.get('dependente.'+i+'.asfValor').patchValue(this.plano.valorFuneralFilhosMaior);
        }else{
          valorInput.value = "0";
          this.dependentes.get('dependente.'+i+'.asfValor').patchValue(0);
        }
      }else{
        if(this.plano.funeralExtra == "SIM"){
          valorInput.value = this.plano.valorFuneralExtra;
          this.dependentes.get('dependente.'+i+'.asfValor').patchValue(this.plano.valorFuneralExtra);
        }else{
          valorInput.value = "0";
          this.dependentes.get('dependente.'+i+'.asfValor').patchValue(0);
        }
      }
    }
  }

  selectAsfEditar(){
    if(this.asfDependente == "NÃO HÁ FUNERAL CONTRATADO"){
      this.asfValorDependente = "0";
    }else{
      if(this.tipoDependente == "FILHO" || this.tipoDependente == "FILHA"){
        if(this.plano.funeralFilhosMaior == "SIM"){
          this.asfValorDependente = this.plano.valorFuneralFilhosMaior;
        }else{
          this.asfValorDependente = "0";
        }
      }else{
        if(this.plano.funeralExtra == "SIM"){
          this.asfValorDependente = this.plano.valorFuneralExtra;
        }else{
          this.asfValorDependente = "0";
        }
      }
    }
  }

  closeEditarDependentes(){
    this.alterando = false;
    this.dependenteAlteracao = [];
    this.dependenteAlterado = [];
    this.tipoAlteracaoDependente = "";
    this.dependentesExcluir = [];
    this.tipoDependente = "";
    this.nomeDependente = "";
    this.nascimentoDependente = "";
    this.cpfDependente = "";
    this.emailDependente = "";
    this.sexoDependente = "";
    this.conectaDependente = "";
    this.checkCienteAdesao = false;
    this.listaDeDependentes.clear();
    let mainRight = document.getElementById('mainRight');
    mainRight.style.display = 'block';
    mainRight.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    let modalDep = document.getElementById('modalDependentes');
    modalDep.style.display = 'none';
  }

  incluirDependentes(){
    this.db.enviarInclusaoExclusaoDependentes(this.usuario.email,this.db.emailDependentes,this.usuario.celular,this.usuario.nome,this.tipoAlteracaoDependente,this.usuario.matricula,this.listaDeDependentes.value).subscribe(res => {
      console.log(res);
      alert("Solicitação Enviada com Sucsso!");
    }, err => {
      console.log(err);
      alert("Solicitação Enviada com Sucsso!");
    })
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let indice = this.arrayEmails.map((itemT:any) => {
      return itemT.email.toLowerCase();
    }).indexOf('financeiro@vivamax.com.br')
    if(indice >= 0){
    let randomInd = Math.random().toString(36).slice(-8);
    let obj = {
      "id": randomInd,
      "data" : this.dataHoje,
      "hora" : hora,
      "titulo" : "INCLUSÃO DEPENDENTES AREA CLIENTE",
      "descricao" : "Associado: "+ this.usuario.nome + " - Matricula: " + Number(this.usuario.matricula),
      "idResponsavel" : this.arrayEmails[indice]._id,
      "responsavel" : this.arrayEmails[indice].nome,
      "resolvido":false,
      "dataResolucao" : "",
      "horaResolucao" : "",
      "prioridade" : "ALTA",
      "origem":"Sistema",
      "idUserSolicitacao":"",
      "nomeSolicitante":"ÁREA DO CLIENTE"
    }
    this.db.postTarefa(obj).subscribe(resT => {
      console.log(resT);
      this.closeEditarDependentes();
    }, errT => console.log(errT))
  }
  }

  excluirDependentes(){
    this.db.enviarInclusaoExclusaoDependentes(this.usuario.email,this.db.emailDependentes,this.usuario.celular,this.usuario.nome,this.tipoAlteracaoDependente,this.usuario.matricula,this.dependentesExcluir).subscribe(res => {
      console.log(res);
      alert("Solicitação Enviada com Sucsso!");
    }, err => {
      console.log(err);
      alert("Solicitação Enviada com Sucsso!");
    })
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let indice = this.arrayEmails.map((itemT:any) => {
      return itemT.email.toLowerCase();
    }).indexOf('financeiro@vivamax.com.br')
    if(indice >= 0){
    let randomInd = Math.random().toString(36).slice(-8);
    let obj = {
      "id": randomInd,
      "data" : this.dataHoje,
      "hora" : hora,
      "titulo" : "EXCLUSÃO DE DEPENDENTES AREA CLIENTE",
      "descricao" : "Associado: "+ this.usuario.nome + " - Matricula: " + Number(this.usuario.matricula),
      "idResponsavel" : this.arrayEmails[indice]._id,
      "responsavel" : this.arrayEmails[indice].nome,
      "resolvido":false,
      "dataResolucao" : "",
      "horaResolucao" : "",
      "prioridade" : "ALTA",
      "origem":"Sistema",
      "idUserSolicitacao":"",
      "nomeSolicitante":"ÁREA DO CLIENTE"
    }
    this.db.postTarefa(obj).subscribe(resT => {
      console.log(resT);
      this.closeEditarDependentes();
    }, errT => console.log(errT))
  }
  }

  selectDependentes(e,dep){
     let marcado = e.srcElement.checked;
    if(marcado){
      this.dependentesExcluir.push(dep);
    }else{
      let idx = this.dependentesExcluir.map((item) => {
        return item.nome;
      }).indexOf(dep.nome);
      this.dependentesExcluir.splice(idx,1);
    }
  }

  openEditDependentes(dependente){
    this.alterando = true;
    this.tipoDependente = dependente.tipo;
    this.nomeDependente = dependente.nome;
    this.nascimentoDependente = dependente.nascimento;
    this.cpfDependente = dependente.cpf;
    this.asfDependente = dependente.asf;
    this.dependenteAlteracao = dependente;
    this.emailDependente = dependente.email;
    this.sexoDependente = dependente.sexo;
    this.conectaDependente = dependente.conecta;
    if(this.asfDependente == "NÃO HÁ FUNERAL CONTRATADO"){
      this.asfValorDependente = "0";
    }else{
      if(this.tipoDependente == "FILHO" || this.tipoDependente == "FILHA"){
        if(this.plano.funeralFilhosMaior == "SIM"){
          this.asfValorDependente = this.plano.valorFuneralFilhosMaior;
        }else{
          this.asfValorDependente = "0";
        }
      }else if(this.tipoDependente == "CÔNJUGE"){
        if(this.plano.funeralConjuge == "AUTOMATICO"){
          this.asfDependente = "FUNERAL AUTOMÁTICO VITALÍCIO";
          this.asfValorDependente = "0";
        }else{
          this.asfDependente = "";
        }
      }else{
        if(this.plano.funeralExtra == "SIM"){
          this.asfValorDependente = this.plano.valorFuneralExtra;
        }else{
          this.asfValorDependente = "0";
        }
      }
    }
    this.dependenteAlteracao.asfValor = this.asfValorDependente;
  }

  closeEditDependente(){
    this.alterando = false;
    this.tipoDependente = "";
    this.nomeDependente = "";
    this.nascimentoDependente = "";
    this.cpfDependente = "";
    this.asfValorDependente = 0;
    this.asfDependente = "";
    this.checkCienteAdesao = false;
    this.dependenteAlteracao = [];
    this.emailDependente = "";
    this.sexoDependente = "";
    this.conectaDependente = "";
  }

  alterarDependentes(){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let randomInd = Math.random().toString(36).slice(-8);
    let indice = this.arrayEmails.map((itemT:any) => {
      return itemT.email.toLowerCase();
    }).indexOf('financeiro@vivamax.com.br')
    if(indice >= 0){
    let obj = {
      "id": randomInd,
      "data" : this.dataHoje,
      "hora" : hora,
      "titulo" : "ALTERAÇÃO DEPENDENTES AREA CLIENTE",
      "descricao" : "Associado: "+ this.usuario.nome + " - Matricula: " + Number(this.usuario.matricula),
      "idResponsavel" : this.arrayEmails[indice]._id,
      "responsavel" : this.arrayEmails[indice].nome,
      "resolvido":false,
      "dataResolucao" : "",
      "horaResolucao" : "",
      "prioridade" : "ALTA",
      "origem":"Sistema",
      "idUserSolicitacao":"",
      "nomeSolicitante":"ÁREA DO CLIENTE"
    }
    this.db.postTarefa(obj).subscribe(resT => {
      console.log(resT)
    }, errT => console.log(errT))
    this.dependenteAlterado = {
      tipo: this.tipoDependente,
      nome: this.nomeDependente,
      nascimento: this.nascimentoDependente,
      cpf: this.cpfDependente,
      asf: this.asfDependente,
      asfValor: this.asfValorDependente,
      email: this.emailDependente,
      sexo: this.sexoDependente,
      conecta: this.conectaDependente
    }
    this.db.enviarAlteraracaoDependentes(this.usuario.email,this.db.emailDependentes,this.usuario.celular,this.usuario.nome,this.tipoAlteracaoDependente,this.usuario.matricula,this.dependenteAlterado,this.dependenteAlteracao).subscribe(res => {
      console.log(res);
      alert("Solicitação Enviada com Sucsso!");
      this.closeEditarDependentes();
    }, err => {
      console.log(err);
      alert("Solicitação Enviada com Sucsso!");
      this.closeEditarDependentes();
    })
   }
  }
  
 printProposta(){
  let divToPrint = document.getElementById('divToPrint').innerHTML;
  let newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  newWindow.document.open();
  newWindow.document.write(`
    <html>
        <head>
          <title>Viva Max - Proposta</title>
          <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
          <script type="text/javascript" src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        </head>
        <body onload="window.print()">${divToPrint}   
        </body>
      </html>
    `);
    newWindow.document.close();
  }

  voltarInicio(){
    this.app.router.navigate(['/inicio']);
  }
  
qtdeConectaDependentes():number{
  let qtdeConecta:number = 0;
  if(this.usuario.dependentes.length > 0){
    for(let i:any = 0; i < this.usuario.dependentes.length; i++){
      if(this.usuario.dependentes[i].conecta){
        qtdeConecta++;
      }
    }
  }
  if(this.usuario.dependentesExtra.length > 0){
    for(let iE:any = 0; iE < this.usuario.dependentesExtra.length; iE++){
      if(this.usuario.dependentesExtra[iE].conecta){
        qtdeConecta++;
      }
    }
  }
  if(this.dependentes.value.dependente.length > 0){
    for(let i:any = 0; i < this.dependentes.value.dependente.length; i++){
      if(this.dependentes.value.dependente[i].conecta){
        qtdeConecta++;
      }
    }
  }
  if(this.dependentesExtra.value.dependenteExtra.length > 0){
    for(let i:any = 0; i < this.dependentesExtra.value.dependenteExtra.length; i++){
      if(this.dependentesExtra.value.dependenteExtra[i].conecta){
        qtdeConecta++;
      }
    }
  }
  return qtdeConecta;
}

checkConecta(e:any,index:any){
  let marcado:any = e.srcElement.checked;
    if(marcado){
      if(this.qtdeConectaDependentes() > this.conectaInclusos){
        let valorInput:any = (<HTMLInputElement>document.getElementById('asfValor'+index));
        valorInput.value = this.valorConectaExtra;
        this.dependentes.get('dependente.'+index+'.asfValor').patchValue(this.valorConectaExtra);
      }
    }else{
      if(this.qtdeConectaDependentes() > this.conectaInclusos){
        let valorInput:any = (<HTMLInputElement>document.getElementById('asfValor'+index));
        valorInput.value = null;
        this.dependentes.get('dependente.'+index+'.asfValor').patchValue(null);
      }
    }    
  }

checkConectaAlterar(e:any){
  let marcado:any = e.srcElement.checked;
    if(marcado){
      if(this.qtdeConectaDependentes() >= this.conectaInclusos){
        let valorInput:any = (<HTMLInputElement>document.getElementById('asfValorDependente'));
        valorInput.value = this.valorConectaExtra;
        this.asfValorDependente = this.valorConectaExtra;
      }
    }else{
      if(this.qtdeConectaDependentes() >= this.conectaInclusos){
         let valorInput:any = (<HTMLInputElement>document.getElementById('asfValorDependente'));
        valorInput.value = null;
        this.asfValorDependente = 0;
      }
    }    
  }

}