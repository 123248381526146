import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DataBaseService {

  urlDataBase:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/http/dbhookweb";
  urlSite:any = "https://cliente.vivamax.com.br"
  apiSMS:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/SMS/smsApi";
  apiConfiguracoes:any = this.urlDataBase+'?coll=configuracoes';
  apiCredenciados:any = this.urlDataBase+'?coll=credenciados';
  apiFormularios:any = this.urlDataBase+'?coll=formularios';
  apiFinanceiro:any = this.urlDataBase+'?coll=financeiro';
  apiAssociados:any = this.urlDataBase+'?coll=associados';
  apiEmpresas:any = this.urlDataBase+'?coll=empresas';
  apiPlanos:any = this.urlDataBase+'?coll=planos';
  apiLogs:any = this.urlDataBase+'?coll=logs';
  apiUsuarios:any = this.urlDataBase+'?coll=usuarios';
  nomeUsuario:any = localStorage.getItem('nomeUsuarioVM');
  emails:any = [];
  emailCancelamento:any = "";
  idCancelamento:any = "";
  responsavelCancelamento:any = "";
  emailSac:any = "";
  idSac:any = "";
  responsavelSac:any = "";
  emailReembolso:any = "";
  idReembolso:any = "";
  responsavelReembolso:any = "";
  emailSeguro:any = "";
  idSeguro:any = "";
  responsavelSeguro:any = "";
  emailDependentes:any = "";
  idDependentes:any = "";
  responsavelDependentes:any = "";
  emailCarteirinhas:any = "";
  idCarteirinhas:any = "";
  responsavelCarteirinhas:any = "";

  constructor(public http:HttpClient){}

  getLoginByUsuario(cpf:any,empresa:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"$or":[{"cpf":"'+cpf+'"},{"dependentes.cpf":"'+cpf+'"},{"dependentesExtra.cpf":"'+cpf+'"}],"empresa":"'+empresa+'","situacao":{"$not":{"$regex":"CANCELADO","$options":"i"}}}&sort={"empresa":-1}');
  }
 
  getLoginByEmpresa(cnpj:any, pwd:any){
    return this.http.get(this.apiEmpresas+'&method=GET&filtro={"cnpj":"'+cnpj+'","senha":"'+pwd+'"}')
  }

  getConfigs(){
    return this.http.get(this.apiConfiguracoes+'&method=GET&sort={"_id":1}');
  }

  getAllUsuarios(){
    return this.http.get(this.apiUsuarios+'&method=GETEMAILUSERS');
  }

  getCredenciados(){
    return this.http.get(this.apiCredenciados+'&method=GET&filtro={"situacao":"ATIVO"}')
  }

  getCredenciadosById(id:any){
    return this.http.get(this.apiCredenciados+'&method=GET&id='+id);
  }

  getCredenciadosCidadeEspecialidade(cid:any,esp:any){
    if(esp == 'LABORATORIO'){
      return this.http.get(this.apiCredenciados+'&method=GET&filtro={"situacao":"ATIVO","municipio":{"$regex":"'+cid+'","$options":"i"},"$or":[{"laboratorioCheck":true},{"especialidade":{"$regex":"'+esp+'","$options":"i"}},{"profissionais.especialidade":{"$regex":"'+esp+'","$options":"i"}}]}&sort={"fantasiaCredenciado":1}');
    }else{
      return this.http.get(this.apiCredenciados+'&method=GET&filtro={"situacao":"ATIVO","municipio":{"$regex":"'+cid+'","$options":"i"},"$or":[{"especialidade":{"$regex":"'+esp+'","$options":"i"}},{"profissionais.especialidade":{"$regex":"'+esp+'","$options":"i"}}]}&sort={"fantasiaCredenciado":1}');
    }
   }

  getAssociadosById(id:any){
    return this.http.get(this.apiAssociados+'&method=GET&id='+id);
  }

  getAssociadosMatricula(key:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"matricula":'+key+',"$and":[{"$or":[{"empresa":"VIVA MAX"},{"empresa":"SEGUROS"},{"empresa":"CONECTA"}]},{"situacao":{"$not":{"$regex":"CANCELADO","$options":"i"}}}]}&sort={"nome":1}');}  

  getAssociadosCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"cpf":"'+cpf+'","$and":[{"$or":[{"empresa":"VIVA MAX"},{"empresa":"SEGUROS"},{"empresa":"CONECTA"}]},{"situacao":{"$not":{"$regex":"CANCELADO","$options":"i"}}}]}&sort={"nome":1}');
  }

  getLoginCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=VERIFICACPF&filtro={"$and":[{"$or":[{"empresa":"VIVA MAX"},{"empresa":"SEGUROS"},{"empresa":"CONECTA"}]},{"$or":[{"cpf":"'+cpf+'"},{"dependentes.cpf":"'+cpf+'"},{"dependentesExtra.cpf":"'+cpf+'"}]}],"situacao":{"$not":{"$regex":"CANCELADO","$options":"i"}}}&sort={"empresa":1}');
  }

  getLoginCnpj(cnpj:any){
    return this.http.get(this.apiEmpresas+'&method=VERIFICACNPJ&filtro={"cnpj":"'+cnpj+'"}');
  }

  getEmpresaSenha(codigoEmpresa:any, cnpj:any){
    return this.http.get(this.apiEmpresas+'&method=VERIFICACNPJCODIGO&filtro={"codigo":"'+codigoEmpresa+'","cnpj":"'+cnpj+'"}');
  }

  getAssociadosRecuperarSenha(cpf:any,matricula:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"$and":[{"$or":[{"empresa":"VIVA MAX"},{"empresa":"SEGUROS"},{"empresa":"CONECTA"}]},{"$or":[{"cpf":"'+cpf+'"},{"dependentes.cpf":"'+cpf+'"},{"dependentesExtra.cpf":"'+cpf+'"}]}],"situacao":{"$not":{"$regex":"CANCELADO","$options":"i"}},"matricula":'+matricula+'}');
  }

  patchAssociado(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCH&id='+id+'&body='+body);
  }

  createAgendaDependente(id:any,cpf:any){
    return this.http.get(this.apiAssociados+'&method=CREATEAGENDADEPENDENTE&id='+id+'&cpf='+cpf)
  }

  createAgendaDependenteExtra(id:any,cpf:any){
    return this.http.get(this.apiAssociados+'&method=CREATEAGENDADEPENDENTEEXTRA&id='+id+'&cpf='+cpf)
  }

  patchDependenteSenha(id:any,nome:any,senha:any,tipo:any,ultimoAcesso:any){
    return this.http.get(this.apiAssociados+'&method=PATCHDEPENDENTESENHA&id='+id+'&senha='+senha+'&nome='+nome+'&tipo='+tipo+'&ultimoAcesso='+JSON.stringify(ultimoAcesso));
  }

  envioSMSRecuperacao(numero,codigo){
    let msg = "Viva Max - Seu Código de Recuperação de Senha: "+codigo;
    let objRequest = {
      numero:numero,
      mensagem:msg
    }
    let str = JSON.stringify(objRequest);
    return this.http.get(this.apiSMS+'?dados='+str);
  }

  getCidadesConfig(){
    return this.http.get(this.apiConfiguracoes+'&method=GET&id=60bff57c2a858830307fa57d');
  }

  getEspecialidadesConfig(){
    return this.http.get(this.apiConfiguracoes+'&method=GET&id=60a268167800532d64f719a5');
  }
  
  getPlanosByTipoByNome(tipo,nome){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"tipo":"'+tipo+'","nome":"'+nome+'"}');
  }

  enviarEmailOutros(arquivo:any,replyto:any,nome:any,mensagem:any,assunto:any){
    let url = this.urlSite+'/email.php?replyto='+replyto+'&nome='+nome+'&mensagem='+mensagem+'&assunto='+assunto;
    return this.http.post(url,arquivo);
  }

  newHistorico(id:any,dados:any){
    let body = JSON.stringify({"historicos":dados});
    return this.http.get(this.apiAssociados+'&method=POSTINSIDE&id='+id+'&body='+body);
  }

  postLogs(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiLogs+'&method=POST&body='+body);
  }

  sendEmailCancelamento(email,emailVM,matricula,protocolo,nome,nomeTitular,telefone,motivo,anexos){
    let url = this.urlSite+'/sendMailCancelamento.php?email='+email+'&emailVM='+emailVM+'&nome='+nome+'&nomeTitular='+nomeTitular+'&motivo='+motivo+'&matricula='+matricula+'&protocolo='+protocolo+'&telefone='+telefone;
    return this.http.post(url,anexos);
  }

  sendEmailSac(email,emailVM,matricula,nome,nomeTitular,telefone,texto,tipoSac){
    let url = this.urlSite+'/sendMailSac.php?email='+email+'&emailVM='+emailVM+'&nome='+nome+'&nomeTitular='+nomeTitular+'&tipoSac='+tipoSac+'&matricula='+matricula+'&texto='+texto+'&telefone='+telefone;
    return this.http.get(url);
  }

  sendEmailReembolso(email,emailVM,matricula,nomeTitular,nome,telefone,tipoReembolso,valorReembolso,anexos){
    let url = this.urlSite+'/sendMailReembolso.php?email='+email+'&emailVM='+emailVM+'&nome='+nome+'&nomeTitular='+nomeTitular+'&tipoReembolso='+tipoReembolso+'&matricula='+matricula+'&valorReembolso='+valorReembolso+'&telefone='+telefone;
    return this.http.post(url,anexos);
  }

  sendEmailSeguro(email,emailVM,matricula,nomeTitular,telefone,tipoSeguro,anexos){
    let url = this.urlSite+'/sendMailSeguro.php?email='+email+'&emailVM='+emailVM+'&nomeTitular='+nomeTitular+'&tipoSeguro='+tipoSeguro+'&matricula='+matricula+'&telefone='+telefone;
    return this.http.post(url,anexos);
  }

  sendEmailCarteirinha(array,endereco,email,emailVM,nomeTitular,valor){
    let url = this.urlSite+'/sendMailCarteirinhas.php?array='+JSON.stringify(array)+'&endereco='+JSON.stringify(endereco)+'&email='+email+'&emailVM='+emailVM+'&nomeTitular='+nomeTitular+'&valor='+valor;
    return this.http.get(url);
  }

  enviarInclusaoExclusaoDependentes(email,emailVM,celular,nomeTitular,tipoAlteracao,matricula,dependentes){  
    let url = this.urlSite+'/sendMailDependentes.php?email='+email+'&emailVM='+emailVM+'&celular='+celular+'&nomeTitular='+nomeTitular+'&tipoAlteracao='+tipoAlteracao+'&matricula='+matricula+'&dependentes='+JSON.stringify(dependentes);
    return this.http.get(url);
  }

  enviarAlteraracaoDependentes(email,emailVM,celular,nomeTitular,tipoAlteracao,matricula,dependenteAlterado,dependenteOriginal){  
    let url = this.urlSite+'/sendMailDependentesAlterar.php?email='+email+'&emailVM='+emailVM+'&celular='+celular+'&nomeTitular='+nomeTitular+'&tipoAlteracao='+tipoAlteracao+'&matricula='+matricula+'&dependenteAlterado='+JSON.stringify(dependenteAlterado)+'&dependenteOriginal='+JSON.stringify(dependenteOriginal);
    return this.http.get(url);
  }

  getFormularios(){
    return this.http.get(this.apiFormularios+'&method=GET');
  }

  getCep(cep:any){
    return this.http.get('https://viacep.com.br/ws/'+cep+'/json/');
  }

  patchEmpresa(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiEmpresas+'&method=PATCH&id='+id+'&body='+body)
  }

  getAssociadosByCodigoEmpresa(codigo:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"codigoEmpresa":"'+codigo+'","$and":[{"$or":[{"empresa":"VIVA MAX"},{"empresa":"CONECTA"}]}]}')
  }
  
  getEmpresasById(id:any){
    return this.http.get(this.apiEmpresas+'&method=GET&id='+id);
  }

  getEmpresaByCodigo(codigo:any){
    return this.http.get(this.apiEmpresas+'&method=GET&filtro={"codigo":"'+codigo+'"}')
  }

  getPlanosEmpresariais(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"tipo":"PLANO EMPRESARIAL"}');
  }
  
  enviarDocAssociado(id:any,imagedata:any){
    let url = this.urlSite+'/upload-doc-associado.php?id='+id;
    return this.http.post(url, imagedata);
  }

  cadastroProposta(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=POST&body='+body);
  }

  patchMatricula(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiConfiguracoes+'&method=PATCH&id='+id+'&body='+body);
  }

  getFinanceiro(key:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"favorecido":"'+key+'","tipo":"Entrada"}');
  }

  getFinanceiroByIdUser(key:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"idUser":"'+key+'","tipo":"Entrada"}');
  }
  
  postTarefa(dados:any){
    let body = JSON.stringify({"tarefas":dados});
    return this.http.get(this.apiUsuarios+'&method=POSTINSIDE&id='+dados.idResponsavel+'&body='+body);
  }

  postEvento(dados:any){
    let body = JSON.stringify({"agenda":dados});
    return this.http.get(this.apiAssociados+'&method=POSTINSIDE&id='+dados.idUser+'&body='+body);
  }

  postEventoDependente(idTitular:any,cpf:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=POSTAGENDADEPENDENTE&id='+idTitular+'&cpf='+cpf+'&body='+body);
  }

   postEventoDependenteExtra(idTitular:any,cpf:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=POSTAGENDADEPENDENTEEXTRA&id='+idTitular+'&cpf='+cpf+'&body='+body);
  }

  patchEvento(idUser:any,idAgenda:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCHAGENDAVENDEDOR&id='+idUser+'&idAgenda='+idAgenda+'&body='+body);
  }

  patchEventoDependente(idTitular:any,cpf:any,idAgenda:any,dados:any,indice:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCHAGENDADEPENDENTE&id='+idTitular+'&cpf='+cpf+'&idAgenda='+idAgenda+'&body='+body+'&indice='+indice);
  }

  patchEventoDependenteExtra(idTitular:any,cpf:any,idAgenda:any,dados:any,indice:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCHAGENDADEPENDENTEEXTRA&id='+idTitular+'&cpf='+cpf+'&idAgenda='+idAgenda+'&body='+body+'&indice='+indice);
  }

  deleteEvento(idUser:any,idAgenda:any){
    return this.http.get(this.apiAssociados+'&method=DELAGENDAVENDEDOR&id='+idUser+'&idAgenda='+idAgenda);
  }

  deleteEventoDependente(idTitular:any,cpf:any,idAgenda:any){
    return this.http.get(this.apiAssociados+'&method=DELETEAGENDADEPENDENTE&id='+idTitular+'&cpf='+cpf+'&idAgenda='+idAgenda);
  }

  deleteEventoDependenteExtra(idTitular:any,cpf:any,idAgenda:any){
    return this.http.get(this.apiAssociados+'&method=DELETEAGENDADEPENDENTEEXTRA&id='+idTitular+'&cpf='+cpf+'&idAgenda='+idAgenda);
  }

  postAssinante(dados){
    /*let options = { headers: {'api_key': 'ak_live_7cf70f90b1e758758efe42f0d72bae6df61fdadd'} };
    return this.http.post('https://api.cyclopay.com/v1/subscriptions',dados,options);*/
     let options = { headers: {'api_key': 'ak_sandbox_7c23696c5ec2eb4a880d7b5457aa42807a60f9b5'} };
    return this.http.post('https://api-sandbox.cyclopay.com/v1/subscriptions',dados,options); 
  }

}

