import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.scss']
})
export class HistoricoComponent implements OnInit {
  agendamentos:any = [];

  constructor(public app:AppComponent,public db:DataBaseService) { }

  ngOnInit(){
    let idUser = localStorage.getItem('idUserVM');
    this.db.getAssociadosById(idUser).subscribe((data:any) => {
      this.agendamentos = data.agendamentos;
    })
  }

    voltarInicio(){
    this.app.router.navigate(['/inicio']);
  }

}