import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarteirinhasComponent } from './carteirinhas/carteirinhas.component';
import { ContratoComponent } from './contrato/contrato.component';
import { CredenciadosComponent } from './credenciados/credenciados.component';
import { AuthGuard } from './guard/auth.guard';
import { HistoricoComponent } from './historico/historico.component';
import { InicioComponent } from './inicio/inicio.component';
import { LoginComponent } from './login/login.component';
import { FormulariosComponent } from './formularios/formularios.component';
import { SorteioComponent } from './sorteio/sorteio.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { AgendaComponent } from './agenda/agenda.component';
import { FinanceiroComponent } from './financeiro/financeiro.component';
import { TelemedicinaComponent } from './telemedicina/telemedicina.component';

const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'inicio', component: InicioComponent, canActivate: [AuthGuard] },
  { path: 'credenciados', component: CredenciadosComponent, canActivate: [AuthGuard] },
  { path: 'contrato', component: ContratoComponent, canActivate: [AuthGuard] },
  { path: 'historico', component: HistoricoComponent, canActivate: [AuthGuard] },
  { path: 'carteirinhas', component: CarteirinhasComponent, canActivate: [AuthGuard] },
  { path: 'sorteio', component: SorteioComponent, canActivate: [AuthGuard] },
  { path: 'formularios', component: FormulariosComponent, canActivate: [AuthGuard] },
  { path: 'agenda', component: AgendaComponent, canActivate: [AuthGuard] },
  { path: 'empresa', component: EmpresaComponent, canActivate: [AuthGuard] },
  { path: 'financeiro', component: FinanceiroComponent, canActivate: [AuthGuard] },
  { path: 'telemedicina', component: TelemedicinaComponent, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
